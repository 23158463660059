<template>
    <v-container>
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <div class="mb-3">
          <v-toolbar>
            <v-btn icon class="hidden-xs-only" @click="$router.back()"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-toolbar-title>{{ this.routeParam.full_name }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div> 
        <v-row class="mt-3">
            <!--system info-->
            <v-col cols="12" md="12">
                <v-toolbar dark color="panelSysInfo"><v-toolbar-title>System Info</v-toolbar-title>
                </v-toolbar>             
                <v-card class="mt-5">
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field v-model="form.merchant_user_id" disabled label="User ID" :rules="inputRules" outlined></v-text-field>
                                <v-text-field v-model="form.merchant_name" disabled label="Merchant Name" outlined></v-text-field>
                                <v-text-field v-model="form.prev_login_date" disabled label="Previous Login Date" outlined></v-text-field>
                                <v-text-field v-model="form.curr_login_date" disabled label="Current Login Date" outlined></v-text-field>
                            </v-col>
                        </v-row>

                    </v-container>

                </v-card>

                
            </v-col>

           <v-col cols="12" md="12">
                <v-toolbar dark color="panel1"><v-toolbar-title>Info</v-toolbar-title>
                    <v-spacer></v-spacer>
                <v-btn icon dark @click="save"><v-icon>save</v-icon></v-btn>
            </v-toolbar>             
                <v-card class="mt-5">
                <v-form ref="mainForm">
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field v-model="form.user_login_id" disabled label="User Login Id" outlined></v-text-field>                                
                                <v-text-field v-model="form.user_password" label="Password" :rules="inputRules" outlined></v-text-field>
                                <v-text-field v-model="form.full_name" label="Full Name" :rules="inputRules" outlined></v-text-field>
                                <v-menu v-model="dpicker.menu1" :close-on-content-click="false" max-width="290">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            outlined
                                            :value="form.start_date"
                                            label="Start Date"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="form.start_date" @change="dpicker.menu1 = false"></v-date-picker>
                                </v-menu>
                                <v-menu v-model="dpicker.menu2" :close-on-content-click="false" max-width="290">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            outlined
                                            :value="form.end_date"
                                            label="Start Date"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="form.end_date" @change="dpicker.menu2 = false"></v-date-picker>
                                </v-menu>

                                <v-select
                                    v-model = "form.status"
                                    :items="status_list"
                                    item-text="description"
                                    item-value="code"
                                    label="Status"
                                    standard
                                    :rules="inputRules"
                                    outlined
                                ></v-select>
                            </v-col>
                        </v-row>

                    </v-container>
                </v-form>

                </v-card>

            </v-col>

        </v-row>

    </v-container>  
</template>
    
<script>
import DlgMessage from '@/components/DlgMessage'
import ApMerchantService from "@/services/ApMerchantService"
import moment from 'moment'
export default {
  components:{DlgMessage},
  props: {
       caller: String
    },
  data() {
    return{
            //breadcrumb
            items: [
              {text:'Merchants',disabled: false,href:'/merchants/',},
              {text:'User',disabled: false,href:'/merchants/',},
              {text:'Details',disabled: false,href:'#',},
              ],
            status_list:[],
            routeParam:[],
            form:{login_id:null,merchant_id:0},

            logo:'',
            encMerchantName:'',

            inputRules: [
             v => !!v || 'This field is required'
            ],

            cpFiles:{
                file_type:'image',
                route_type:'merchants',
                showDialog:false,
            },
            dpicker:{menu1: false,menu2: false,},

    }
  },
  async created() {
    this.routeParam['merchant_id'] = this.$route.params.merchant_id;
    this.routeParam['merchant_name'] = atob(this.$route.params.enc_merchant_name);
    this.routeParam['user_id'] = this.$route.params.user_id;
    this.routeParam['full_name'] = atob(this.$route.params.enc_full_name);
    //console.log(this.$route)
    this.viewDetail();

  },
  
  methods:{

    async viewDetail(){
        this.form.merchant_user_id = this.routeParam.user_id
        try{
            ApMerchantService.view_merchant_user(this.form).then((res) => {
                this.form = res.data.data;
                for (const [key, value] of Object.entries(this.form)) {
                    if(value == null){
                        this.form[key] = "";
                    }
                }                
                if(this.form.start_date == "0000-00-00" || !this.form.start_date)
                    this.form.start_date = moment(String(new Date())).format('YYYY-MM-DD');
                
                if(this.form.end_date == "0000-00-00" || !this.form.end_date)
                    this.form.end_date = moment(String(new Date())).format('YYYY-MM-DD');
                this.status_list = res.data.statusList; 
                
            }).catch((e)=>{
                console.log(e.response);
                this.$refs.dlgMsg.showDlgBox(true,'error','View User',e.response.data.error_message);
            })
            ;
        } catch(e) {
            console.log(e);
            //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
        }
    },
    async save(){
        try{
            ApMerchantService.update_merchant_user(this.form).then(() => {
                this.$refs.dlgMsg.showDlgBox(true,'success','Update User','Success.');
            }).catch((e)=>{
                console.log(e.response);
                this.$refs.dlgMsg.showDlgBox(true,'error','Update User',e.response.data.error_message);
            })
            ;
        } catch(e) {
            console.log(e);
            //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
        }
    },
    
    dlgClose(){
        this.visible = false;        
    },

  }
}
</script>